<template>
    <div
        v-if="oneCompany"
        class="main__container main__padding"
        :style="`${$route.query.app == 1 ? 'margin-top: -20px;' : ''}`"
    >
        <div class="row__start">
            <div class="backbutton pointer" @click.stop="goToHome">
                <ArrowLeftActiveSvg />
                <!-- <FullArrowLeftActiveSvg /> -->
            </div>

            <!-- <p class="test">-</p> -->
            <h2 class="pointer" @click.stop="goToHome">Suche</h2>
        </div>
        <div
            :style="
                `${
                    $route.query.app == 1
                        ? 'margin-top: 15px;'
                        : 'margin-top: 10px'
                }`
            "
        ></div>
        <h2 class="mt-0">
            {{ oneCompany.name }}
        </h2>
        <CompanyHeader />
        <div class="row__start company__row" style="align-items: flex-start">
            <div
                class="row__start--flex1 image__row"
                v-if="oneCompany.images.length != 0"
            >
                <CompanyImages :id="oneCompany.id" />
            </div>
            <div class="row__start--flex1 image__row vanish" v-else>
                <div
                    class="general__box"
                    v-if="oneCompany.aboutUs && $route.query.app != 1"
                >
                    <h3 class="text__bold">Über uns</h3>
                    <p class="mt-15">{{ oneCompany.aboutUs }}</p>
                </div>
            </div>
            <div class="row__start--flex3 row__flex3--w43">
                <div class="appointmentBox" v-if="!$route.query.app == 1">
                    <h1>Termin vereinbaren</h1>
                    <p
                        class="mt-15 mb-60"
                        v-if="oneCompany.hasWorkshops == true"
                    >
                        Um einen Termin mit uns zu buchen, stehen Dir an
                        unterschiedlichen Tagen und Zeiträume verschiedene
                        Ansprechpartner zur Verfügung. Du kannst wählen ob Du
                        per Telefon, Videomeeting oder Chat mit uns
                        kommunizieren möchtest. <br />Wir freuen uns auf Deine
                        Terminbuchung (15 Minuten pro Termin) und Deine
                        anschließende Kontaktaufnahme über die Daten, die Dir
                        per E-Mail nach der Buchung mitgeteilt werden.
                    </p>
                    <p
                        class="mt-15 mb-60"
                        v-if="oneCompany.hasWorkshops == false"
                    >
                        Bitte über unsere allgemeinen Kontaktdaten einen Termin
                        vereinbaren.
                    </p>
                    <div
                        class="absoluteBox absoluteBox--right"
                        style="display:flex;"
                        v-if="oneCompany.hasWorkshops == true"
                    >
                        <!-- <p style="margin-top:10px; margin-right:15px;">
                            Diese Funktion ist momentan nicht verfügbar.
                        </p>
                        <a class="button button--grey"> -->
                        <a
                            class="button button--big"
                            @click.stop="openAppointmentModal({ id })"
                        >
                            <p class="text__white">Jetzt Termin buchen</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$route.query.app == 1" class="tooltip-bar">
            <div v-if="oneCompany.hasWorkshops == true">
                <a
                    class="button button-slim2 mt-10"
                    @click.stop="openAppointmentModal({ id })"
                >
                    <p class="text__white">Jetzt Termin buchen</p>
                </a>
            </div>
            <div v-if="oneCompany.hasWorkshops == false">
                <a class="button button--grey mt-10">
                    <p class="text__white">Jetzt Termin buchen</p>
                </a>
            </div>
            <div class="tooltip--trigger mt-15 ml-10" style="color: $primary;">
                <InfoTagSvg />

                <span class="tooltip--text">
                    <p v-if="oneCompany.hasWorkshops == true">
                        Um einen Termin mit uns zu buchen, stehen Dir an
                        unterschiedlichen Tagen und Zeiträume verschiedene
                        Ansprechpartner zur Verfügung. Du kannst wählen ob Du
                        per Telefon, Videomeeting oder Chat mit uns
                        kommunizieren möchtest. <br />Wir freuen uns auf Deine
                        Terminbuchung (15 Minuten pro Termin) und Deine
                        anschließende Kontaktaufnahme über die Daten, die Dir
                        per E-Mail nach der Buchung mitgeteilt werden.
                    </p>
                    <p v-else>
                        Bitte über unsere allgemeinen Kontaktdaten einen Termin
                        vereinbaren.
                    </p>
                </span>
            </div>
        </div>

        <CompanyContent :id="oneCompany.id" :showAboutUs="showAbout" />
        <company-appointment-modal
            :company_id="id"
            :showModal="showAppointmentModal"
            @close="showAppointmentModal = false"
        />
        <Footer v-if="!$route.query.app == 1" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyAppointmentModal from "../components/CompanyAppointmentModal/Modal";
export default {
    components: { CompanyAppointmentModal },
    name: "Company",
    props: ["id"],
    data() {
        return {
            showAppointmentModal: false,
            showAbout: false,
        };
    },
    methods: {
        ...mapActions(["getOneCompany", "getCompanyChatTypes"]),
        goToHome() {
            this.$router.push({
                name: "Home",
            });
        },
        async openAppointmentModal({ id }) {
            let target = event.target;
            if (target.classList.contains("button")) {
                target = target.childNodes[0];
            }
            target.innerHTML = "<div class='loading'></div>";
            await this.getCompanyChatTypes({ id });
            target.innerHTML = "Jetzt Termin buchen";
            this.showAppointmentModal = true;
        },
    },
    created() {
        this.getOneCompany({ id: this.id });
    },
    computed: {
        ...mapGetters(["oneCompany", "token"]),
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1100px) {
    .vanish {
        display: none;
    }
}
.tooltip-bar {
    display: flex;
}
.tooltip--trigger {
    position: relative;
    display: block;
    width: 20px;
    margin-right: 20px;
    font-size: 30;
    .tooltip--text {
        visibility: hidden;
        width: 250px;
        background-color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        border: 1px solid black;
        position: absolute;
        z-index: 1;
        top: 110%;
        left: -450%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }
    &:hover {
        cursor: pointer;
        path {
            fill: black;
        }
        .tooltip--text {
            visibility: visible;
            opacity: 1;
        }
    }
}
</style>
